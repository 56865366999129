export const socialMedia = [
    {
      id: 1,
      img: "/git.svg",
      href: "https://github.com/davidmugisha47", 
    },
    {
      id: 2,
      img: "/gmail.svg",
      href: "mailto:davidkhalifa47@gmail.com", 
    },
    {
      id: 3,
      img: "/link.svg",
      href: "https://www.linkedin.com/in/david-mugisha1/", 
    },
  ];
  

  export const projects = [
    {
      id: 1,
      title: "Majestic Creative",
      des: "Majestic Creative Agency",
      img: "https://res.cloudinary.com/dc84tiq6o/image/upload/f_auto,q_auto/qx1aoj0qsrlcl8g4loig",
      iconLists: ["/html.svg", "/css.svg", "/scss.svg", "/tail.svg", "/java.svg"],
      link: "Comming soon",
    },
    {
      id: 2,
      title: "AbaNorbert Immigration",
      des: "AbaNorbert Imigration Services",
      img: "https://res.cloudinary.com/dc84tiq6o/image/upload/f_auto,q_auto/oshttsooqk7zxnsufhd7",
      iconLists: ["/re.svg", "/tail.svg", "/ts.svg", "/stripe.svg", "/java.svg"],
      link: "https://abanorbert.ca/",
    },
    {
      id: 3,
      title: "Fatherhood Tech",
      des: "Fatherhood Tech Portfolio",
      img: "https://res.cloudinary.com/dc84tiq6o/image/upload/f_auto,q_auto/agn5qcpnhygovz0uaome",
      iconLists: ["/html.svg", "/java.svg", "./gsap.svg", "/scss.svg", "/css.svg"],
      link: "Underdevelopment",
    },
    {
      id: 4,
      title: "SAWA",
      des: "Studio of African Wildlife Conservation Art",
      img: "https://res.cloudinary.com/dc84tiq6o/image/upload/f_auto,q_auto/avnntvldicocyiwuqsbg",
      iconLists: ["/re.svg", "/tail.svg", "/ts.svg", "/node.svg"],
      link: "https://studioofafricanwildlifeart.com/",
    },
    {
        id: 5,
        title: "Lyrics Globe",
        des: "Lyrics Api",
        img: "https://res.cloudinary.com/dc84tiq6o/image/upload/f_auto,q_auto/vzb8o3yy4f3mvut3akcy",
        iconLists: ["/next.svg", "/tail.svg", "/ts.svg", "/java.svg", "/graph.svg"],
        link: "Underdevelopment",
      },
      {
        id: 6,
        title: "Iraba Artisians",
        des: "Artisians ",
        img: "https://res.cloudinary.com/dc84tiq6o/image/upload/f_auto,q_auto/bbxz9thvj3yztyb9nagb",
        iconLists: ["/re.svg", "/tail.svg", "/ts.svg", "/scss.svg", "/node.svg"],
        link: "Underdevelopment",
      },
  ];